import * as React from "react"
import { graphql } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Page from '../../components/Page'
import OtherIndustries from "../../components/OtherIndustries";
import IndustriesHeroSection from "../../components/IndustriesHeroSection"
import IndustriesBanner from "../../components/IndustriesBottomBanner";
import HeroSection from "../../components/Industries/HeroSection";
import IndustriesPage from "../../components/IndustriesPage";

const data = {
  heroSection: {
    heading: ['Aerospace & Defense', ' Manufacturing'], 
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/aerospace-logo.png',
    content: [
      'Deliver high-quality products on-time amidst demand pressure, while meeting certification standards and minimizing the cost of quality.', 
      "Loopr's AI-based quality inspection & traceable compliance automation solutions work for all aerospace component manufacturers"
    ]
  }, 
  contentSection: [
    {
      type: 'primary', 
      headerContent: {
        labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-icon.png', 
        labelImgDimensions: {
          width: '3.81rem', 
          height: '4.68rem'
        },
        heading: 'Automate defect detection in Composites Manufacturing & MRO', 
        description: 'Fast, low-cost and accurate inspection of wrinkles/FOD, material micro-structures, turbine blades and paint surfaces.', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/aerospace-content-01.png', 
        imgDimensions: {
          width: '43.93rem', 
          height: '23.5rem'
        }
      }, 
      content: [
        {
          title: 'Problem', 
          text: [
            'A laminates manufacturer relies on manual inspection to identify defects such as blisters, wrinkles, and voids in their laminate sheets during the first production stage', 
            '100% visual inspection was slow, expensive, and failed to detect defects early on. This led to finished sheets not bonding together and substantial losses.'
          ]
        }, 
        {
          title: 'Solution', 
          text: [
            "Loopr's camera-based software was customized to recognize all defects occurring in the laminate sheets and deployed on first stage production lines", 
            'Defects were captured in real-time as they occurred, and alerts sent to the line supervisor to scrap the defected sheets early-on.'
          ]
        }, 
        {
          title: 'Outcome', 
          text: [
            {
              primaryText: '100% Visual inspection', 
              description: 'Which resulted in dollar savings from accurate and timely rejection of defected sheets in first stage.'
            }, 
            {
              primaryText: 'Cost of Quality', 
              description: 'Was reduced by leveraging human labor for more complex jobs on factory floor.'
            }
          ]
        }
      ]
    },
    {
      type: 'light', 
      headerContent: {
        labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-eye-icon.png', 
        labelImgDimensions: {
          width: '5.06rem', 
          height: '3.75rem'
        },
        heading: 'Automate quality checks in MRO', 
        description: 'Consistent inspection of turbine blades, painted surfaces and more.', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/aerospace-content-02.png', 
        imgDimensions: {
          width: '44.31rem', 
          height: '23.5rem'
        }
      }, 
      content: [
        {
          title: 'Problem', 
          text: [
            'An American MRO facility is struggling to inspect turbine blades and aircraft wings for rust and paint chips in a timely manner.', 
            'The inspection results are inconsistent because of difficult to access inspection surfaces and varying experience levels of operators.'
          ]
        }, 
        {
          title: 'Solution', 
          text: [
            "LooprIQ Inspect is deployed to a hand-held tablet which is attached to an endoscopic camera.", 
            'Operators inspect the challenging surfaces using the flexible camera system, which also provides objective inspection results and saves a digital proof of record.'
          ]
        }, 
        {
          title: 'Outcome', 
          text: [
            {
              primaryText: 'Timely inspection', 
              description: 'Time to inspect each part is significantly reduced, improving overall throughput of the MRO facility.'
            }, 
            {
              primaryText: 'Consistent results', 
              description: 'AI assisted inspections result in objective quality checks irrespective of the operator’s experience or fatigue level.'
            }
          ]
        }
      ]
    },
    // {
    //   type: 'primary', 
    //   headerContent: {
    //     labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/document-audit-icon.png', 
    //     labelImgDimensions: {
    //       width: '3.43rem', 
    //       height: '3.56rem'
    //     },
    //     heading: 'Document Audit & Compliance Checks', 
    //     description: 'Automate the process of validating traveler sheet data against specifications to ensure fast, low-cost and accurate compliance', 
    //     imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/aerospace-content-03.png', 
    //     imgDimensions: {
    //       width: '52.06rem', 
    //       height: '28.81rem'
    //     }
    //   }, 
    //   content: [
    //     {
    //       title: 'Problem', 
    //       text: [
    //         'A Tier 2 paint and coatings supplier with high volume and high product mix, has frequent recalls arising from errors in verifying traveler sheets', 
    //         'Quality personnel spend hours every day manually reviewing work order travelers for process compliance per AS9100 and ISO9001, before shipping'
    //       ]
    //     }, 
    //     {
    //       title: 'Solution', 
    //       text: [
    //         "Loopr's smartphone-based app was customized to identify manufacturing data points listed in traveler sheets", 
    //         'The listed data points were automatically compared to tolerance limits. Faulty processes were reported to MRB along with a non-conformance report and rework orders'
    //       ]
    //     }, 
    //     {
    //       title: 'Outcome', 
    //       text: [
    //         {
    //           primaryText: '3 hours saved', 
    //           description: 'On time spent doing manual paperwork every day, allowing operators to focus on improving production output instead'
    //         }, 
    //         {
    //           primaryText: '95% error free', 
    //           description: 'compliance checks resulting in half the recalls and substantial cost savings'
    //         }
    //       ]
    //     }
    //   ]
    // }
  ]
}


export default function AerospacePage(){

  return <IndustriesPage data={data} />

}

export const query = graphql`
  query aerospaceUses {
          file(relativePath: {eq: "aerospace-hero.jpg"}) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          allMarkdownRemark(sort: {fields: frontmatter___index, order: ASC}){
          nodes {
          frontmatter {
            image
            index
            title
            category
            mobileImage
          }
          html
          id
      }
    }
  }
`
